import {usersSelectors} from "@features/users";

// @ts-ignore
import resources from "@locales";
import {dayjsBase} from "@shared/services/dayjs";
import i18next from "i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import {useEffect} from "react";
import {initReactI18next, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export const fallbackLng = "en";

export const initI18n = () => {
  i18next
    .use(BrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      defaultNS: "common",
      fallbackNS: "common",
      interpolation: {escapeValue: false},
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "li", "ul", "ol", "em", "tt", "pre"],
      },
      fallbackLng,
    });

  // Pre-select with the resolved language (meaning "fr" instead of "fr-FR")
  dayjsBase.locale(i18next.resolvedLanguage);
};

export const useUserLocale = () => {
  const {i18n} = useTranslation();
  const user = useSelector(usersSelectors.selectEditing);

  useEffect(() => {
    // take the locale either from the user or from the i18n object.
    // Always take the resolved language as the main language
    // (for ex is language is "fr_FR", always force "fr" as the language)
    const newLocale = user.locale ? user.locale : i18n.resolvedLanguage;
    if (i18n.language !== newLocale) {
      i18n.changeLanguage(newLocale);
      dayjsBase.locale(newLocale);
    }
  }, [i18n, user?.locale]);
};
